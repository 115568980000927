import React from "react"
import {PageProps} from "gatsby"
import dayjs from "dayjs"
import "dayjs/locale/nl"

import {NewsItem} from "../../types"

import Layout from "../components/layout"
import PageWidth from "../components/page-width"
import SingleBanner from "../components/single-banner"

const NewsItemTemplate = (page: PageProps): JSX.Element => {
  const data = page.pageContext as NewsItem
  dayjs.locale("nl")

  return (
    <Layout title={data.title} currentPage={data}>
      <SingleBanner
        bannerTitle="Nieuws"
        bannerImage={{
          url:
            "https://www.datocms-assets.com/37731/1610466114-homepage-about-2.jpg",
        }}
        size="small"
      />
      <PageWidth text>
        <h3>{data.title}</h3>
        <p>
          <em>
            Geplaatst op: {dayjs(data.date).format("D MMMM YYYY").toLowerCase()}
          </em>
        </p>
        <p>
          <strong>{data.teaser}</strong>
        </p>
        <div dangerouslySetInnerHTML={{__html: data.content}} />
      </PageWidth>
    </Layout>
  )
}

export default NewsItemTemplate
